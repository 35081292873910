<div class="container text-center">
  <div class="row justify-content-md-center">
    <div class="col-12 col-sm-6">
      <div class="logo">
        <img [src]="storeLogo" />
      </div>
      <h4>Registrar oferta</h4>
      @if(storeName){
      <p style="margin-bottom: 20px">Escaneie o cupom para validar a oferta.</p>
      }
      <div class="text-center">
        @if(scannerEnabled){
        <div style="width: 100%;">
          <!-- <div class="element"></div> -->
          <zxing-scanner
            (scanSuccess)="scanSuccessHandler($event)"
            (scanError)="scanErrorHandler($event)"
            [enable]="scannerEnabled"
            [formats]="allowedFormats"
            
          ></zxing-scanner>
          <div
            style="
            position: fixed;
            bottom: 60px;
            right: 0;
            left: 0;
              text-align: center;
            "
          >
            <button
            style="background-color: white;"
              mat-stroked-button
              color="primary"
              (click)="openManualCode($event)"
            >
              Digitar manualmente
            </button>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
</div>
