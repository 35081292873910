import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { NgIcon, provideIcons } from '@ng-icons/core';
import { heroXMark } from '@ng-icons/heroicons/outline';


@Component({
  selector: 'app-error-modal',
  standalone: true,
  imports: [MatDialogModule, NgIcon, MatButtonModule],
  providers: [
    provideIcons({
      heroXMark,
    }),
  ],
  templateUrl: './error-modal.component.html',
  styleUrl: './error-modal.component.scss'
})
export class ErrorModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public content: any
  ) {}

  confirm() {
    this.dialogRef.close(true);
  }
}
