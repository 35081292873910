import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { Router } from '@angular/router';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { BarcodeFormat } from '@zxing/library';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-qr',
  standalone: true,
  imports: [ZXingScannerModule, MatButtonModule],
  templateUrl: './qr.component.html',
  styleUrl: './qr.component.scss',
})
export class QrComponent implements OnInit {
  public scannerEnabled = true;
  allowedFormats = [BarcodeFormat.QR_CODE];
  public loading = false;
  public storeName;
  public storeLogo;


  constructor(private appService: AppService, private router: Router) {
    this.storeName = appService.getDecodedAccessToken()?.store_name ?? '';
    this.storeLogo = appService.getDecodedAccessToken()?.shopping_logo_url ?? '../../../assets/img/gitly-logo.png';
  }

  ngOnInit(): void {
    const token = this.appService.getDecodedAccessToken();
    if(!token) 
      this.router.navigate(['/']);
  }

  openManualCode(event: any) {
    this.router.navigate(['/manualScan']);
  }

  scanSuccessHandler(event: any) {
    if (this.loading) return;
    this.loading = true;
    let body = {
      store_uuid: this.appService.getDecodedAccessToken().store_uuid,
      code: event,
    };
    // this.scannerEnabled = false;
    this.consumer(body);
  }
  consumer(body: any) {
    this.appService.consumeOffer(body).subscribe({
      next: (v: any) => {
        let text = v.resp?.offer;
        if (!text) text = v.payload?.offer;
        this.router.navigate(['/msgSuccess'], { queryParams: { offer: text } });
      },
      error: (e) => {
        this.router.navigate(['/msgError'], {
          queryParams: { msg: e.error.error || e.error.resp, status: e.status },
        });
      },
    });
  }

  scanErrorHandler(event: any) {
    console.log('scanErrorHandler', event);
    // this.errMsg = 'Erro ao ler cupom';
  }
}
