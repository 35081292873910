import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private URL_BASE = environment.apiAppUrl;
  private URL_CONSUME = environment.consumeOffer;

  constructor(private http: HttpClient) {}

  consumeOffer(body: any) {
    const token = localStorage.getItem('token') as any;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
    const options = { headers: headers };
    const {shopping_id} = this.getDecodedAccessToken();

    return this.http.post(
      this.URL_BASE + shopping_id + this.URL_CONSUME,
      body,
      options
    );
  }

  getEventId(){
    return localStorage.getItem('token');
  }

  getDecodedAccessToken(): any {
    try {
      const token = localStorage.getItem('token');
      if (token) return jwt_decode.jwtDecode(token);
      else return null;
    } catch (Error) {
      return null;
    }
  }
}
