<div class="container text-center">
  <div class="row justify-content-md-center">
    <div class="col-12 col-md-4">
      <div class="logo">
        <img [src]="storeLogo" />
      </div>
      <div class="text-center">
        <div class="d-flex justify-content-center">
          <div class="icon-valid">
            <ng-icon name="heroCheck" />
          </div>
        </div>
        <div style="margin: 40px 0">
          <h2>Validado com sucesso!</h2>
          <p>
            O código da oferta <strong>{{ offer }}</strong> foi verificado e
            validado para a promoção.
          </p>
        </div>
        <div class="col-12 d-flex flex-column text-center">
          <button mat-flat-button color="primary" (click)="enableScan()">
            Registrar nova oferta
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
