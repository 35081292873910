<mat-dialog-content>
  <h4 style="display: flex; justify-content: space-between">
    {{ content.title }}
    <ng-icon
      (click)="closeDialog()"
      name="heroXMark"
      id="close-button"
      style="cursor: pointer"
    ></ng-icon>
  </h4>
  <br>
  <p style="color: black;">{{ content.msg }}</p>
  <div mat-dialog-actions align="end">
    <button mat-stroked-button (click)="secondaryButtonAction()">
      {{ content.secondaryButton.text }}
    </button>
    @if(content.mainButton){
      <button
        mat-flat-button
        color="primary"
        (click)="mainButtonAction()"
        cdkFocusInitial
        style="border-radius: 4px !important;"
      >
        {{ content.mainButton.text }}
      </button>
    }
  </div>
</mat-dialog-content>
