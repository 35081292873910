<router-outlet></router-outlet>
<footer>
  <p>
    v.{{ version }} <br>
    Desenvolvido por
    <a href="https://pt.gitly.com.br/">
      <img
        src="../assets/img/gitly-logo.png"
        style="height: 20px"
      />
    </a> 
  </p>
</footer>
