import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private URL_BASE = environment.apiAdminUrl;
  private EVENT_MANAGEMENT = environment.eventManagement;
  private CONSUME_TICKET = environment.consumeTicket;

  constructor(private http: HttpClient) {}
  
  getEvent$(){
    const token = localStorage.getItem('token') as any;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
    const options = { headers: headers };
    const {shopping_id} = this.getDecodedAccessToken();
    const eventId = localStorage.getItem('event_id');

    return this.http.get(
      this.URL_BASE + shopping_id + this.EVENT_MANAGEMENT + '/' + eventId,
      options
    );
  }

  consumeTicket$(body: any) {
    const token = localStorage.getItem('token') as any;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
    const options = { headers: headers };
    const {shopping_id} = this.getDecodedAccessToken();
    const eventId = localStorage.getItem('event_id');

    return this.http.post(
      this.URL_BASE + shopping_id + this.EVENT_MANAGEMENT + '/' + eventId + this.CONSUME_TICKET,
      body,
      options
    );
  }

  getDecodedAccessToken(): any {
    try {
      const token = localStorage.getItem('token');
      if (token) return jwt_decode.jwtDecode(token);
      else return null;
    } catch (Error) {
      return null;
    }
  }
}
