<div
  class="row"
  style="padding-inline: 24px; height: 160px; --bs-gutter-x: 0 !important"
>
  <div
    class="col-2"
    style="align-self: center; justify-content: center; display: flex"
  >
    @if(go_back_show){<button
      mat-stroked-button
      [disabled]=""
      (click)="goBack()"
    >
      Voltar</button
    >}
  </div>
  <img
    style="max-height: 100%; object-fit: contain"
    [src]="event_url ?? '../../../assets/img/gitly-logo.png'"
    alt="event-image"
    class="col-8"
  />
  <div
    class="col-2"
    style="align-self: center; justify-content: center; display: flex"
  >
    @if(go_forward_show){<button
      mat-stroked-button
      (click)="goForward.emit()"
      [disabled]="go_forward_disabled"
    >
      Avançar</button
    >}
  </div>
</div>
