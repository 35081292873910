import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-event',
  standalone: true,
  imports: [MatButtonModule, RouterModule],
  templateUrl: './event.component.html',
  styleUrl: './event.component.scss',
})
export class EventComponent{
  @Input() event_url: string | null = null;
  @Input() go_forward_disabled: boolean = false;
  @Input() go_back_show: boolean = true;
  @Input() go_forward_show: boolean = true;
  @Output() public goForward = new EventEmitter();

  constructor(private _location: Location) {}

  goBack(){
    this._location.back();
  }
}
