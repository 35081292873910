import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { SuccessComponent } from './pages/msg/success/success.component';
import { ErrorComponent } from './pages/msg/error/error.component';
import { QrComponent } from './pages/scan/qr/qr.component';
import { CodeComponent } from './pages/scan/code/code.component';
import { QrEventComponent } from './pages/event/qr-event/qr-event.component';
import { SlotSelectionComponent } from './pages/event/slot-selection/slot-selection.component';

export const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'qrScan', component: QrComponent },
    { path: 'event', children:[
      { path: '', component: SlotSelectionComponent },
      { path: 'qrScan/:id', component: QrEventComponent },
    ] },
    { path: 'manualScan', component: CodeComponent },
    { path: 'msgSuccess', component: SuccessComponent },
    { path: 'msgError', component: ErrorComponent },
  ];
  