import { Component, signal, effect } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { AdminService } from '../../../services/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgIcon, provideIcons } from '@ng-icons/core';
import { heroClock, heroQrCode } from '@ng-icons/heroicons/outline';
import { EventComponent } from '../event.component';

@Component({
  selector: 'app-slot-selection',
  standalone: true,
  imports: [MatTabsModule, CommonModule, NgIcon, EventComponent],
  providers: [
    provideIcons({
      heroClock,
      heroQrCode,
    }),
  ],
  templateUrl: './slot-selection.component.html',
  styleUrl: './slot-selection.component.scss',
})
export class SlotSelectionComponent {
  event: any;
  selectedId: any = null;

  readonly slots = signal<any>([]);
  firstSlotId: string = '';
  objectKeys = Object.keys;
  // sub: Subscription;

  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    // this.sub = this.router.events.subscribe((event: any) => {
    //   console.log(event.snapshot?.url);
    // });
  }

  ngOnInit(): void {
    this.getEvent();
  }

  ngOnDestroy() {
    // this.sub.unsubscribe();
  }

  getEvent() {
    this.adminService.getEvent$().subscribe({
      next: (v: any) => {
        if (!v || !v.payload) return;
        this.event = v.payload.event;

        if(this.event.event_type === 'unique'){
          this.goToScanner();
          return;
        }

        if(!v.payload.slots || v.payload.slots.length <= 0) return;
        
        this.firstSlotId = v.payload.slots[0]._id;
        const slots = v.payload.slots.reduce((group: any, product: any) => {
          product.slot_info.dt_start_slot = this.getLocalDate(
            product.slot_info.dt_start_slot
          );
          product.slot_info.dt_end_slot = this.getLocalDate(
            product.slot_info.dt_end_slot
          );
          const date = product.slot_info.dt_start_slot.toLocaleDateString();
          group[date] = group[date] ?? [];
          group[date].push(product);
          return group;
        }, []);
        this.slots.set(slots.sort());
      },
      error: (e) => {},
    });
  }

  selectSlot(id: string){
    if(this.selectedId !== id)
      this.selectedId = id;
    else
      this.selectedId = null;
  }

  goToScanner(){
    this.router.navigate([this.router.url, 'qrScan', (this.selectedId ?? this.firstSlotId)]);
  }

  getLocalDate = (date: any) => {
    let utcDate = new Date(date);
    const offsetMinutes = utcDate.getTimezoneOffset();
    const localTime = new Date(utcDate.getTime() - offsetMinutes * 60 * 1000);
    return localTime;
  };
}
