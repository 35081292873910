<div class="container text-center">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-4" style="margin: 40px 0">
        <div class="logo">
          <img [src]="storeLogo" />
        </div>
        @if(loading){
            <div style="display: flex; justify-content: center; margin-bottom: 30px;">
              <mat-spinner></mat-spinner>
            </div>
            <p>Verificando seu acesso ao sistema.</p>
        }
        @else if (error) {
            <p>Acesso negado. <br> Sistema de acesso exclusiva a lojista</p>
        }
      </div>
    </div>
  </div>
  