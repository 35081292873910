<app-event [event_url]="event?.data_info?.img_url" [go_back_show]="false" [go_forward_disabled]="selectedId === null" (goForward)="goToScanner()"/>

<div style="display: flex; justify-content: center">
  <mat-tab-group class="col-11 col-lg-8">
     @for(slotsDay of objectKeys(slots()); track slotsDay){
    <mat-tab [label]="slotsDay">
      <div class="row">
        @for(slot of slots()[slotsDay]; track slot){
        <div
          class="slot-card col-3"
          (click)="selectSlot(slot._id)"
          [class.selected]="selectedId === slot._id"
        >
          <ng-icon name="heroClock" />
          {{ slot.slot_info.dt_start_slot | date : "HH:mm" }} -
          {{ slot.slot_info.dt_end_slot | date : "HH:mm" }}
          <ng-icon name="heroQrCode" />
          {{ slot.slot_info.n_redeemed }}
          <span style="font-weight: 400">
            / {{ slot.slot_info.n_consumed }}</span
          >
        </div>
        }
      </div>
    </mat-tab>
    }
  </mat-tab-group>
</div>
