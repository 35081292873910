<mat-dialog-content>
  <h4 style="display: flex; justify-content: space-between;">
    {{content.title ?? "Erro ao realizar leitura!"}}
    <ng-icon (click)="confirm()" name="heroXMark" id="close-button" style="cursor: pointer;"></ng-icon>
  </h4>
  <p>{{ content.msg }}</p>
  <div mat-dialog-actions align="end">
    <button mat-flat-button color="primary" (click)="confirm()" cdkFocusInitial>
      Tentar Novamente
    </button>
  </div>
</mat-dialog-content>
