{
  "name": "gmall-qrcodeconsume",
  "version": "0.1.4",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "build:dev": "ng build --configuration development",
    "build:prod": "ng build --configuration production",
    "publish": "ng build --base-href /gitly/admin/",
    "pages_build": "if test \"$CF_PAGES_BRANCH\" = \"main\" ; then ng build --configuration production ; else ng build --configuration=development ; fi "
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.0",
    "@angular/cdk": "^17.3.10",
    "@angular/common": "^17.3.0",
    "@angular/compiler": "^17.3.0",
    "@angular/core": "^17.3.0",
    "@angular/forms": "^17.3.0",
    "@angular/material": "^17.3.10",
    "@angular/platform-browser": "^17.3.0",
    "@angular/platform-browser-dynamic": "^17.3.0",
    "@angular/router": "^17.3.0",
    "@ng-icons/core": "^27.5.0",
    "@ng-icons/heroicons": "^27.5.0",
    "@zxing/library": "^0.21.0",
    "@zxing/ngx-scanner": "^18.0.0",
    "jwt-decode": "^4.0.0",
    "lz-string": "^1.5.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.1",
    "@angular/cli": "^17.3.1",
    "@angular/compiler-cli": "^17.3.0",
    "@types/jasmine": "~5.1.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.4.2"
  }
}
