import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BarcodeFormat } from '@zxing/library';
import { AppService } from '../../../services/app.service';
import { MatButtonModule } from '@angular/material/button';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { AdminService } from '../../../services/admin.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmClientDialogComponent } from '../confirm-client-dialog/confirm-client-dialog.component';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { EventComponent } from '../event.component';

@Component({
  selector: 'app-qr-event',
  standalone: true,
  imports: [ZXingScannerModule, MatButtonModule, EventComponent],
  templateUrl: './qr-event.component.html',
  styleUrl: './qr-event.component.scss',
})
export class QrEventComponent {
  public scannerEnabled = true;
  allowedFormats = [BarcodeFormat.QR_CODE];
  public loading = false;
  public storeName;
  public storeLogo;
  public eventId: string | null = null;

  event: any;
  slots: any[] = [];
  currentSlot: any;
  id: any;

  constructor(
    private appService: AppService,
    private adminService: AdminService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.storeName = appService.getDecodedAccessToken()?.store_name ?? '';
    this.storeLogo =
      appService.getDecodedAccessToken()?.shopping_logo_url ??
      '../../../assets/img/gitly-logo.png';

      this.route.params.subscribe((params) => {
        if (params && params['id']) this.id = params['id'];
      });
  }

  ngOnInit(): void {
    const token = this.appService.getDecodedAccessToken();
    this.eventId = this.appService.getEventId();
    if (!token || !this.eventId) this.router.navigate(['/']);

    this.getEvent();
  }

  // openManualCode(event: any) {
  //   this.router.navigate(['/manualScan']);
  // }

  getEvent() {
    this.adminService.getEvent$().subscribe({
      next: (v: any) => {
        if (!v || !v.payload) return;
        this.event = v.payload.event;
        this.slots = v.payload.slots;
        this.slots = this.slots.sort((a: any, b: any) => {
          const aDate = new Date(a.slot_info.dt_start_slot);
          const bDate = new Date(b.slot_info.dt_start_slot);
          return aDate.getDate() - bDate.getDate();
        } );
        this.currentSlot = this.slots.find((slot: any) => slot._id === this.id);
      },
      error: (e) => {},
    });
  }

  scanSuccessHandler(event: any) {
    if (this.loading) return;
    this.loading = true;
    let body = {
      ticket_code_qr: event,
    };
    // this.scannerEnabled = false;
    this.consume(body);
  }

  consume(body: any) {
    this.adminService.consumeTicket$(body).subscribe({
      next: (v: any) => {
        this.adminService.getEvent$().subscribe({
          next: (v: any) => {
            if (!v || !v.payload) return;
            this.event = v.payload.event;
            this.slots = v.payload.slots;
            this.slots = this.slots.sort((a: any, b: any) => {
              const aDate = new Date(a.slot_info.dt_start_slot);
              const bDate = new Date(b.slot_info.dt_start_slot);
              return aDate.getDate() - bDate.getDate();
            } );

            this.event.event_type = this.event.event_type ?? 'unique';

            switch (this.event.event_type) {
              case 'slot':
                this.currentSlot = this.slots.find((slot: any) => slot._id === this.id);
                break;
              case 'slot_queue':
                this.currentSlot = this.slots.find((slot: any) => slot._id === this.id);
                break;
              case 'unique':
                this.currentSlot = this.slots[0];
                break;
            }
            if(this.currentSlot.slot_info.n_consumed < this.currentSlot.slot_info.n_max_slot){
              const content = {
                title: "Cliente liberado!",
                msg: `Cliente ${this.currentSlot.slot_info.n_consumed} de ${this.currentSlot.slot_info.n_max_slot} cupons resgatados`,
                mainButton: {
                  text: 'Voltar',
                  action: () => {this.router.navigate(['..'], { relativeTo: this.route })},
                },
                secondaryButton: {
                  text: 'Continuar',
                }
              }
              this.openConfirmModal(content);
            }
            //Slot cheio
            else{
              const currentSlotIndex = this.slots.findIndex((slot: any) => slot._id === this.id);
              const nextSlotId = this.slots[currentSlotIndex + 1]?._id;
              if(nextSlotId){
                const content = {
                  title: "Slot Completo!",
                  msg: 'Todos os cupons desse slot foram resgatados. O próximo cliente está alocado na fila do próximo slot. \nDeseja continuar?',
                  secondaryButton: {
                    text: 'Sair',
                    action: () => {this.router.navigate(['../..'], { relativeTo: this.route })},
                  },
                  mainButton: {
                    text: 'Continuar',
                    action: () => {this.router.navigate(['..', nextSlotId], { relativeTo: this.route })},
                  }
                }
                this.openConfirmModal(content);
              }
              //Fim dos slots
              else{
                const content = {
                  title: "Evento finalizado!",
                  msg: 'Todos os cupons desse evento foram resgatados. Não há mais sessões no evento.',
                  secondaryButton: {
                    text: 'Sair',
                    action: () => {this.router.navigate(['../..'], { relativeTo: this.route })},
                  },
                }
                this.openConfirmModal(content)
              }
            }
          },
          error: (e) => {},
        });
      },
      error: (e) => {
        this.openErrorModal(e.error);
      },
      complete: () => (this.loading = false),
    });
  }

  openConfirmModal(payload: any) {
    const dialogRef = this.dialog.open(ConfirmClientDialogComponent, {
      width: '400px',
      data: payload,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.loading = false;
    });
  }
  openErrorModal(error: any) {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      width: '400px',
      data: {
        msg: error.msg,
        title: error.status == 409 ? "Cupom já validado anteriormente!" : "Erro ao realizar leitura!"
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.loading = false;
    });
  }

  scanErrorHandler(event: any) {
    console.log('scanErrorHandler', event);
    // this.errMsg = 'Erro ao ler cupom';
  }
}
