<app-event [event_url]="event?.data_info?.img_url" [go_back_show]="event?.event_type !== 'unique'" [go_forward_show]="false"/>

<div class="container text-center" style="margin-top: 20px;">
  <div class="row justify-content-center">
    <div class="col-12 col-sm-8 col-lg-4">
      <h4>Registrar Entrada de Cliente</h4>
      <p style="margin-bottom: 60px; font-size: 20px;">Escaneie o cupom para validar a entrada</p>
      <div class="text-center">
        @if(scannerEnabled){
        <div style="width: 100%">
          <!-- <div class="element"></div> -->
          <zxing-scanner
            (scanSuccess)="scanSuccessHandler($event)"
            (scanError)="scanErrorHandler($event)"
            [enable]="scannerEnabled"
            [formats]="allowedFormats"
            [enable]="!loading"
          ></zxing-scanner>
          <div
          >
            <div style="display: flex; justify-content: space-between">
              <div style="font-weight: 700; font-size: 14px;">Cupons Disponibilizados</div>
              <div>{{currentSlot?.slot_info.n_max_slot}}</div>
            </div>
            <div style="display: flex; justify-content: space-between">
              <div style="font-weight: 700; font-size: 14px;">Cupons Registrados</div>
              <div>{{currentSlot?.slot_info.n_redeemed}}</div>
            </div>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
</div>
