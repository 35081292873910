import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgIcon, provideIcons } from '@ng-icons/core';
import { heroCheck } from '@ng-icons/heroicons/outline';
import { AppService } from '../../../services/app.service';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-success',
  standalone: true,
  imports: [NgIcon, MatButtonModule],
  templateUrl: './success.component.html',
  providers:[provideIcons({heroCheck})],
  styleUrl: './success.component.scss'
})
export class SuccessComponent {
  offer: any;
  storeLogo: ''

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService
  ) {

    this.storeLogo = appService.getDecodedAccessToken()?.shopping_logo_url ?? '../../../assets/img/gitly-logo.png';
    route.queryParams.subscribe(
      (params) =>
        (this.offer = params['offer'] || '')
    );
  }
  enableScan(){
    this.router.navigate(['/qrScan']);
  }
}
