
<div class="container">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-4">
        <h4 class="text-start">
          <ng-icon name="heroChevronLeft" style="cursor: pointer;"
          (click)="enableScan()"/>Registrar oferta
        </h4>
  
        <div style="margin-top: 40px;">
            <form #manualForm="ngForm">
              <div class="col-12">
                <mat-label>Código de oferta do cliente</mat-label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    #manualCode_input
                    required
                    name="manualCode_input"
                    [(ngModel)]="manualCode"
                    placeholder="ex: 64af3f2498b1ac7f2d3637d6"
                  />
                </mat-form-field>
              </div>
              <div class="col-12 d-flex flex-column text-center">
                <button
                  mat-flat-button
                  color="primary"
                  style="margin-top: 20px"
                  (click)="registerCod()"
                  [disabled]="manualForm.invalid"
                >
                  Registrar código
                </button>
              </div>
            </form>
  
        </div>
      </div>
    </div>
  </div>
  
  