import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NgIcon, provideIcons } from '@ng-icons/core';
import { heroExclamationTriangle } from '@ng-icons/heroicons/outline';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [NgIcon, MatButtonModule],
  providers:[provideIcons({heroExclamationTriangle})],
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss'
})
export class ErrorComponent {
  errMsg: any;
  errTitle: any;
  storeLogo: ''
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService
  ) {

    this.storeLogo = appService.getDecodedAccessToken()?.shopping_logo_url ?? '../../../assets/img/gitly-logo.png';
    route.queryParams.subscribe(
      (params) =>{
        this.errMsg = params['msg'] || '';
        this.errTitle = params['status'] == 409 ? "Cupom já validado anteriormente!" : "Ops, houve um erro!"
      }
    );
  }

  enableScan() {
    this.router.navigate(['/qrScan']);
  }
}
