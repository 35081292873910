import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { NgIcon, provideIcons } from '@ng-icons/core';
import { heroXMark } from '@ng-icons/heroicons/outline';

@Component({
  selector: 'app-confirm-client-dialog',
  standalone: true,
  imports: [MatDialogModule, NgIcon, MatButtonModule],
  providers: [
    provideIcons({
      heroXMark,
    }),
  ],
  templateUrl: './confirm-client-dialog.component.html',
  styleUrl: './confirm-client-dialog.component.scss',
})
export class ConfirmClientDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmClientDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public content: {
      title: string;
      msg: string;
      mainButton: {
        text: string;
        action: Function;
      };
      secondaryButton: {
        text: string;
        action: Function;
      };
    }
  ) {
  }

  mainButtonAction(): void {
    if(this.content.mainButton.action)
      this.content.mainButton.action();
    this.closeDialog();
  }
  secondaryButtonAction() {
    if(this.content.secondaryButton.action)
      this.content.secondaryButton.action();
    this.closeDialog();
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
