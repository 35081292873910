import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { Router } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { NgIcon, provideIcons } from '@ng-icons/core';
import { heroChevronLeft } from '@ng-icons/heroicons/outline';

@Component({
  selector: 'app-code',
  standalone: true,
  imports: [
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    NgIcon,
  ],
  templateUrl: './code.component.html',
  styleUrl: './code.component.scss',
  providers: [provideIcons({ heroChevronLeft })],
})
export class CodeComponent implements OnInit {
  constructor(private appService: AppService, private router: Router) {}

  ngOnInit(): void {
    const token = this.appService.getDecodedAccessToken();
    if(!token) 
      this.router.navigate(['/']);
  }

  public manualCode = '';

  registerCod() {
    let body = {
      store_uuid: this.appService.getDecodedAccessToken().store_uuid,
      code: this.manualCode,
    };
    this.consumer(body);
  }

  consumer(body: any) {
    this.appService.consumeOffer(body).subscribe({
      next: (v: any) => {
        let text = v.resp?.offer;
        if (!text) text = v.payload?.offer;
        this.router.navigate(['/msgSuccess'], { queryParams: { offer: text } });
      },
      error: (e) => {
        this.router.navigate(['/msgError'], {
          queryParams: { msg: e.error.error || e.error.resp, status: e.status },
        });
      },
    });
  }
  enableScan() {
    this.router.navigate(['/qrScan']);
  }
}
