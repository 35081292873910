import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../services/app.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Title } from '@angular/platform-browser';
import * as LZString from 'lz-string';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [MatProgressSpinnerModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  public token: string | null = '';
  public eventId: string | null = '';
  public loading = true;
  public error = false;
  public storeName: any;
  storeLogo = '';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService,
    private titleService: Title
  ) {
    route.queryParams.subscribe((params) => {
      this.token = params['token'];
      if (this.token) {
        this.token = this.decompressString(this.token);
        localStorage.setItem('token', this.token);
        this.storeLogo =
          appService.getDecodedAccessToken()?.shopping_logo_url ??
          '../../../assets/img/gitly-logo.png';
        if (appService.getDecodedAccessToken()?.role) {
          this.titleService.setTitle(
            appService.getDecodedAccessToken().role === 3
              ? 'Gmall - Scanner Promotor'
              : 'GMall - Scanner Lojista'
          );
        }
      }
      this.eventId = params['eventId'];
      if (this.eventId) {
        this.eventId = this.decompressString(this.eventId);
        localStorage.setItem('event_id', this.eventId);
      }
    });
  }
  ngOnInit(): void {
    if (this.token) {
      if (!this.eventId) {
        this.router.navigate(['/qrScan']);
      } else {
        this.router.navigate(['/event']);
      }
    } else {
      this.loading = false;
      this.error = true;
    }
  }

  // Função para descomprimir a string
  decompressString(compressed: string): string {
    const res = LZString.decompressFromEncodedURIComponent(compressed);
    return res;
  }
}
